








































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import stackChart from '@/components/chart/stackChart.vue'
import {
  fetchFamilySummary,
  fetchFamilyMainMember,
  fetchFamilyMembers,
  fetchFamilyLifeInsurance,
  fetchFamilyCriticalInsurance,
  fetchFamilyLifeInsuranceInfos,
  fetchFamilyCriticalInsuranceInfos,
  familyInsuranceList,
  familyInsuranceSave,
  familyTransaction,
  familyInsuranceDelete,
  fetchChildrenSort
} from '@/api/family'
import { getFinancialAnalysisInfo } from '@/api/articles'
import { familyBaseText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'
import { delcommafy, comdify } from '@/utils/validate'

@Component({
  name: 'basePlanDetail',
  components: {
    moduleHeader,
    stackChart,
    PageButtomText
  },
  computed: {
    insuranceMainObj: () => (arr: Array<object>, val:string, key:string) => arr.find((item:any) => item[key] === val) || {}
  }
})
export default class extends Vue {
  familyBaseText = familyBaseText
  isLoading = false
  mainMember = {
    id: ''
  }
  memberList = [] as Array<{}>
  selectMainYearOptions = [] as Array<{}>
  insuranceMainData: {
    [key: string]: any
  } = {}
  selectListYearOptions: {
    [key: string]: any
  } = {}
  insuranceListData: {
    [key: string]: any
  } = {}
  lifelData = {}
  lifeMainData: any = {}
  criticalMemberListData: {
    [key: string]: any
  } = {}
  criticalData = {}
  lifelListData = []
  criticalListData = {}
  summaryData = []
  summaryListData = {}
  insuranceMainParam = {
    insuranceType: 'LifeInsurance',
    orderField: 'ranking',
    containsRegularLife: true
  }
  insuranceListParam: {
    [key: string]: any
  } = {}
  insuranceMainOptions = []
  insuranceListOptions = {}
  inAmount = 0
  insuranceMainValue = ''
  insuranceListValue: {
    [key: string]: any
  } = {}
  selectYearOptions = [
    {
      label: '趸交',
      value: '1'
    }, {
      label: '5年',
      value: '5'
    }, {
      label: '10年',
      value: '10'
    }, {
      label: '20年',
      value: '20'
    }
  ]
  selectYearMainValue = ''
  yearlyAmount: number|string = ''
  yearlyAmountStr = ''
  selectYearListValue: {
    [key: string]: any
  } = {}
  enterMainMny = ''
  enterListMny: {
    [key: string]: any
  } = {}
  yearlyAmountList: {
    [key: string]: any
  } = {}
  yearlyAmountListStr: {
    [key: string]: any
  } = {}
  stackMainOption = null
  stackListOption = {}
  incomePercentage: number|string = ''
  incomePercentageList: {
    [key: string]: any
  } = {}
  created() {
    this.preFetch()
    this.initFetch()
  }
  accAdd(arg1: number, arg2: number) { // 加法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  }
  accSub(arg1: number, arg2: number) { // 减法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    const n = (r1 >= r2) ? r1 : r2
    return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
  }
  accDiv(arg1: number, arg2: number) { // 乘法
    let c = 0
    const d = arg1.toString()
    const e = arg2.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) {
      // s
    }
    try {
      c += e.split('.')[1].length
    } catch (f) {
      // s
    }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  }
  accMul(arg1: number, arg2: number) { // 除法
    let t1 = 0
    let t2 = 0
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    const r1 = Number(arg1.toString().replace('.', ''))
    const r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  }
  deleteInsurance() {
    familyInsuranceDelete({ customerId: CommonMudule.customerId }).then((res) => {
      if (res.success && res.statusCode === 0) {
        this.$message.success('操作成功!')
        setTimeout(() => {
          this.$router.push({
            // name: 'baseHome',
            path: '/basicSecurity/index',
            params: {
              name: 'reset'
            }})
        }, 1500)
      } else {
        this.$message.error(res.errorMsg)
      }
    })
  }
  enterListMnyChange(data: any) {
    if (this.enterListMny[data.id] && this.selectYearListValue[data.id]) {
      // const yearlyAmount = Number(this.enterListMny[data.id]) / Number(this.selectYearListValue[data.id])
      // const incomePercentage = yearlyAmount * 100 / (this.inAmount || 1)
      this.yearlyAmountList[data.id] = ''
      this.yearlyAmountListStr[data.id] = ''
      this.incomePercentageList[data.id] = ''
    }
    if (this.insuranceListValue[data.id] && this.enterListMny[data.id] && this.selectYearListValue[data.id]) {
      const opt = {
        id: undefined,
        customerId: CommonMudule.customerId,
        customerMemberId: data.id,
        insuranceId: this.insuranceListValue[data.id],
        insuranceType: 'CriticalIllnessInsurance',
        totalAmount: this.enterListMny[data.id],
        years: this.selectYearListValue[data.id]
      }
      if (this.criticalMemberListData[data.id] && this.criticalMemberListData[data.id].id) {
        opt.id = this.criticalMemberListData[data.id].id
      }
      familyInsuranceSave(opt).then((res: any) => {
        if (res.success && res.statusCode === 0) {
          this.fetchTransactions('CriticalIllnessInsurance', data.id)
          this.fetchCritical(data)
        } else {
          this.$message.error(res.errorMsg)
        }
      })
    }
  }
  clickListSort(id: any, val: any) {
    if (val) {
      this.insuranceListParam[id].orderField = val
    }
    this.fetchInsurance(id)
  }
  initSelectKeys(str: string, id?: string) {
    const list: Array<any> = []
    const arr = str.split(',')
    arr.forEach((item) => {
      if (item === '1') {
        list.push({
          label: '趸交',
          value: '1'
        })
      } else {
        list.push({
          label: item + '年',
          value: item + ''
        })
      }
    })
    if (id) {
      this.selectListYearOptions[id] = list
    } else {
      this.selectMainYearOptions = list
    }
  }
  insuranceMainEvent() {
    let insuranceMainOptionsKeys = ''
    this.insuranceMainData.forEach((item: any) => {
      if (item.id === this.insuranceMainValue) {
        insuranceMainOptionsKeys = item.paymentTerms || ''
      }
    })
    this.initSelectKeys(insuranceMainOptionsKeys)
    this.lifelData = {}
    this.lifelListData = []
    this.stackMainOption = null
    this.selectYearMainValue = ''
  }
  insuranceListEvent(id?: string) {
    if (!id) {
      return false
    }
    let insuranceMainOptionsKeys = ''
    this.insuranceListData[id].forEach((item: any) => {
      if (item.id === this.insuranceListValue[id]) {
        insuranceMainOptionsKeys = item.paymentTerms || ''
      }
    })
    this.initSelectKeys(insuranceMainOptionsKeys, id)
    this.$set(this.criticalData, id, {})
    this.$set(this.criticalListData, id, [])
    this.$set(this.stackListOption, id, undefined)
    this.selectYearListValue[id] = ''
  }
  enterMainMnyChange() {
    if (this.enterMainMny && this.selectYearMainValue) {
      // const yearlyAmount = Number(this.enterMainMny) / Number(this.selectYearMainValue)
      // const incomePercentage = yearlyAmount * 100 / (this.inAmount || 1)
      this.yearlyAmount = ''
      this.yearlyAmountStr = ''
      this.incomePercentage = ''
    }
    if (this.insuranceMainValue && this.enterMainMny && this.selectYearMainValue) {
      const data = {
        id: this.lifeMainData.id ? this.lifeMainData.id : undefined,
        customerId: CommonMudule.customerId,
        customerMemberId: this.mainMember.id,
        insuranceType: 'LifeInsurance',
        insuranceId: this.insuranceMainValue,
        totalAmount: this.enterMainMny,
        years: this.selectYearMainValue
      }
      familyInsuranceSave(data).then((res: any) => {
        if (res.success && res.statusCode === 0) {
          this.fetchTransactions('LifeInsurance', this.mainMember.id)
          this.fetchLife(this.mainMember)
        } else {
          this.$message.error(res.errorMsg)
        }
      })
    }
  }
  async fetchMainInsurance() {
    const data = cloneDeep(this.insuranceMainParam)
    if (data.containsRegularLife) {
      // @ts-ignore
      delete data.containsRegularLife
    }
    const insuranceMainOptions: Array<{}> = []
    const res = await familyInsuranceList(data)
    if (res.success && res.statusCode === 0) {
      res.data.forEach((item: any) => {
        insuranceMainOptions.push({
          ...item,
          label: item.name,
          value: item.id
        })
      })
    } else {
      this.$message.error(res.errorMsg)
    }
    this.insuranceMainData = res.data
    this.$set(this, 'insuranceMainOptions', insuranceMainOptions)
    if (this.selectYearMainValue) {
      let insuranceMainOptionsKeys = ''
      this.insuranceMainData.forEach((item: any) => {
        if (item.id === this.insuranceMainValue) {
          insuranceMainOptionsKeys = item.paymentTerms || ''
        }
      })
      this.initSelectKeys(insuranceMainOptionsKeys)
    }
  }
  fetchInsurance(id: any) {
    const data = cloneDeep(this.insuranceListParam[id])
    if (data.doNotGroup) {
      delete data.doNotGroup
    }
    let insuranceListOptions: Array<{}> = []
    familyInsuranceList(data).then((res) => {
      if (res.success && res.statusCode === 0) {
        insuranceListOptions = res.data
      } else {
        this.$message.error(res.errorMsg)
      }
      this.$set(this.insuranceListOptions, id, insuranceListOptions)
      this.$set(this.insuranceListData, id, res.data)
      if (this.selectYearListValue[id]) {
        let insuranceMainOptionsKeys = ''
        this.insuranceListData[id].forEach((item: any) => {
          if (item.id === this.insuranceListValue[id]) {
            insuranceMainOptionsKeys = item.paymentTerms || ''
          }
        })
        this.initSelectKeys(insuranceMainOptionsKeys, id)
      }
    })
  }
  clickMainSort(val: any) {
    console.log(val)
    if (val) {
      this.insuranceMainParam.orderField = val
    }
    this.fetchMainInsurance()
  }
  fetchLife(data: any) {
    if (data) {
      fetchFamilyLifeInsurance({
        customerId: CommonMudule.customerId,
        customerMemberId: data.id
      }).then((res) => {
        if (res.success && res.statusCode === 0) {
          if (res.data) {
            res.data.totalPremiumStr = comdify(Number(res.data.totalPremium || 0).toString())
            res.data.lifeBenefitsStr = comdify(Number(res.data.lifeBenefits || 0).toString())
            res.data.bonusStr = comdify(Number(res.data.bonus || 0).toString())
            res.data.cashValueStr = comdify(Number(res.data.cashValue || 0).toString())
          }
          this.$set(this, 'lifelData', res.data)
        } else {
          this.$message.error(res.errorMsg)
        }
      })
      fetchFamilyLifeInsuranceInfos({
        customerId: CommonMudule.customerId,
        customerMemberId: data.id
      }).then((res) => {
        if (res.success && res.statusCode === 0) {
          if (res.data && res.data.length) {
            const stackDataYearMny: Array<number> = []
            const stackDataYear: Array<number> = []
            const stackDataBonus: Array<number> = []
            const stackAge: Array<number> = []
            this.yearlyAmount = res.data[0]['yearMny']
            this.yearlyAmountStr = comdify(Number(res.data[0]['yearMny'] || 0).toString())
            this.incomePercentage = (Number(res.data[0]['yearMny'] || 0) * 100 / (this.inAmount || 1)).toFixed(2)
            res.data.forEach((item: any) => {
              stackDataYearMny.push(item.lifeMny)
              stackDataYear.push(-item.yearMny)
              stackDataBonus.push(item.bonus)
              stackAge.push(item.age)
              item.lifeMnyStr = comdify(Number(item.lifeMny || 0).toString())
              item.yearMnyStr = comdify(Number(item.yearMny || 0).toString())
              item.bonusStr = comdify(Number(item.bonus || 0).toString())
              item.cashValueStr = comdify(Number(item.cashValue || 0).toString())
            })
            this.setStackOption(stackDataYearMny, stackDataYear, stackDataBonus, stackAge)
          }
          this.$set(this, 'lifelListData', res.data)
        } else {
          this.$message.error(res.errorMsg)
        }
      })
    }
  }
  setStackOption(stackDataYearMny: Array<number>, stackDataYear: Array<number>, stackDataBonus: Array<number>, stackAge: Array<number>, id?: string) {
    let max = 0
    let min = 0
    stackDataYearMny.forEach((item, idx) => {
      const bonus = stackDataBonus[idx] ? stackDataBonus[idx] : 0
      if (this.accAdd(item, bonus) > max) {
        max = this.accAdd(item, bonus)
      }
    })
    stackDataYear.forEach((item) => {
      if (item > max) {
        max = item
      }
      if (item < min) {
        min = item
      }
    })
    const len = stackAge.length
    let wid = 20
    if (len) {
      wid = Math.floor(this.accMul(700, len))
      wid = wid > 10 ? wid - 6 : 4
    }
    const option = {
      color: ['#003C9D ', '#880000', '#227700'],
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        data: ['年保费', id ? '重疾保障' : '寿险保额', '红利'],
      },
      xAxis: [{
        axisLabel: {
          interval: 10,
          onZero: false
        },
        data: stackAge
      }],
      yAxis: {
        max: max,
        min: min,
        scale: true,
        axisLine: {
          onZero: false,
          show: false,
          interval: 0
        },
        stackLabels: { // 堆叠数据标签
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'gray'
          }
        }
      },
      series: [
        {
          name: '年保费',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataYear
        },
        {
          name: id ? '重疾保障' : '寿险保额',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataYearMny
        }, {
          name: '红利',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataBonus
        }
      ]
    }
    if (id) {
      this.$set(this.stackListOption, id, option)
    } else {
      this.$set(this, 'stackMainOption', option)
    }
  }
  fetchCritical(data: any) {
    fetchFamilyCriticalInsurance({
      customerId: CommonMudule.customerId,
      customerMemberId: data.id
    }).then((res) => {
      if (res.success && res.statusCode === 0) {
        if (res.data) {
          res.data.totalPremiumStr = comdify(Number(res.data.totalPremium || 0).toString())
          res.data.highAmountStr = comdify(Number(res.data.highAmount || 0).toString())
          res.data.specialAmountStr = comdify(Number(res.data.specialAmount || 0).toString())
          res.data.expireAmountStr = comdify(Number(res.data.expireAmount || 0).toString())
        }
        this.$set(this.criticalData, data.id, res.data)
      } else {
        // this.$message.error(res.errorMsg)
      }
    })
    fetchFamilyCriticalInsuranceInfos({
      customerId: CommonMudule.customerId,
      customerMemberId: data.id
    }).then((res) => {
      if (res.success && res.statusCode === 0) {
        if (res.data && res.data.length) {
          const stackDataYearMny: Array<number> = []
          const stackDataYear: Array<number> = []
          const stackDataBonus: Array<number> = []
          const stackAge: Array<number> = []
          this.yearlyAmountList[data.id] = res.data[0]['yearMny']
          this.yearlyAmountListStr[data.id] = comdify(Number(res.data[0]['yearMny'] || 0).toString())
          this.incomePercentageList[data.id] = (Number(res.data[0]['yearMny'] || 0) * 100 / (this.inAmount || 1)).toFixed(2)
          res.data.forEach((item: any) => {
            stackDataYearMny.push(item.highAmount)
            stackDataYear.push(-item.yearMny)
            stackDataBonus.push(item.bonus)
            stackAge.push(item.age)
            item.yearMnyStr = comdify(Number(item.yearMny || 0).toString())
            item.highAmountStr = comdify(Number(item.highAmount || 0).toString())
            item.midAmountStr = comdify(Number(item.midAmount || 0).toString())
            item.lowAmountStr = comdify(Number(item.lowAmount || 0).toString())
          })
          this.setStackOption(stackDataYearMny, stackDataYear, stackDataBonus, stackAge, data.id)
        }
        this.$set(this.criticalListData, data.id, res.data)
      } else {
        this.$message.error(res.errorMsg)
      }
    })
  }
  fetchSummaryMain(data: any) {
    fetchFamilySummary({
      code: 'code0031',
      customerId: CommonMudule.customerId,
      customerMemberId: data.id
    }).then((res) => {
      if (res.success && res.statusCode === 0) {
        if (res.data) {
          res.data.currentAmountStr = res.data.currentAmount ? comdify(Number(res.data.currentAmount / 10000).toString()) : res.data.currentAmount
          res.data.totalAmountStr = res.data.totalAmount ? comdify(Number(res.data.totalAmount / 10000).toString()) : res.data.totalAmount
          res.data.gapAmountStr = res.data.gapAmount ? comdify(Number(res.data.gapAmount / 10000).toString()) : res.data.gapAmount
          this.$set(this, 'summaryData', [res.data])
        } else {
          this.$set(this, 'summaryData', null)
        }
      } else {
        this.$message.error(res.errorMsg)
      }
    })
  }
  fetchSummary(data: any, idx: number) {
    let code = ''
    if (data.id === this.mainMember.id) {
      code = 'code0032'
    } else {
      if (data.memberType === 'Child') {
        code = 'code0035'
      } else {
        if (data.memberType === 'Self') {
          code = 'code0033'
        } else if (data.memberType === 'Mate') {
          code = 'code0034'
        }
      }
    }
    fetchFamilySummary({
      code: code,
      customerId: CommonMudule.customerId,
      customerMemberId: data.id
    }).then((res) => {
      if (res.success && res.statusCode === 0) {
        if (res.data) {
          res.data.currentAmountStr = res.data.currentAmount ? comdify(Number(res.data.currentAmount / 10000).toString()) : res.data.currentAmount
          res.data.totalAmountStr = res.data.totalAmount ? comdify(Number(res.data.totalAmount / 10000).toString()) : res.data.totalAmount
          res.data.gapAmountStr = res.data.gapAmount ? comdify(Number(res.data.gapAmount / 10000).toString()) : res.data.gapAmount
          this.$set(this.summaryListData, data.id, [res.data])
        } else {
          this.$set(this.summaryListData, data.id, null)
        }
      } else {
        this.$message.error(res.errorMsg)
      }
    })
  }
  isNum(val: any) {
    return typeof val === 'number' && !isNaN(val)
  }
  async fetchTransactions(type: string, id: string, cb?: any) {
    const res = await familyTransaction({
      customerId: CommonMudule.customerId,
      customerMemberId: id,
      insuranceType: type
    })
    if (res.success && res.statusCode === 0) {
      if (res.data) {
        if (type === 'LifeInsurance') {
          this.insuranceMainValue = res.data.insuranceId
          this.enterMainMny = res.data.totalAmount
          this.selectYearMainValue = this.isNum(res.data.years) ? res.data.years + '' : ''
          this.yearlyAmount = res.data.yearlyAmount
          this.incomePercentage = res.data.incomePercentage ? Number(res.data.incomePercentage || 0).toFixed(2) : res.data.incomePercentage
          this.lifeMainData = res.data
          this.fetchLife({
            id: id,
            type: 'LifeInsurance'
          })
          if (cb) {
            cb()
          }
        } else {
          this.criticalMemberListData[id] = res.data
          this.insuranceListValue[id] = res.data.insuranceId
          this.enterListMny[id] = res.data.totalAmount
          this.selectYearListValue[id] = this.isNum(res.data.years) ? res.data.years + '' : ''
          this.yearlyAmountList[id] = res.data.yearlyAmount
          this.yearlyAmountListStr[id] = comdify(Number(res.data.yearlyAmount || 0).toString())
          this.incomePercentageList[id] = res.data.incomePercentage ? Number(res.data.incomePercentage || 0).toFixed(2) : res.data.incomePercentage
          this.fetchCritical({
            id: id
          })
          if (cb) {
            cb(id)
          }
        }
      } else {
        if (type === 'LifeInsurance') {
          if (cb) {
            cb()
          }
        } else {
          cb(id)
        }
      }
    } else {
      if (type === 'LifeInsurance') {
        if (cb) {
          cb()
        }
      } else {
        cb(id)
      }
      this.$message.error(res.errorMsg)
    }
  }
  async initFetch() {
    const res = await Promise.all([
      fetchFamilyMainMember({ customerId: CommonMudule.customerId }),
      fetchFamilyMembers({ customerId: CommonMudule.customerId }),
      fetchChildrenSort({ customerId: CommonMudule.customerId })
    ])
    let memberList = []
    const ChildList: Array<any> = []
    const MainList = []
    const NextList = []
    let mainMember
    if (res[0].success && res[0].statusCode === 0) {
      const id = res[0].data || ''
      if (res[1].success && res[1].statusCode === 0) {
        for (let i = 0; i < res[1].data.length; i++) {
          if (res[1].data[i].memberType !== 'Child') {
            if (res[1].data[i].id === id) {
              mainMember = cloneDeep(res[1].data[i])
              MainList.push(res[1].data[i])
            } else {
              NextList.push(res[1].data[i])
            }
          }
          // if (res[1].data[i].memberType === 'Child') {
          //   ChildList.push(res[1].data[i])
          // } else {
          //   if (res[1].data[i].id === id) {
          //     mainMember = cloneDeep(res[1].data[i])
          //     MainList.push(res[1].data[i])
          //   } else {
          //     NextList.push(res[1].data[i])
          //   }
          // }
        }
        if (res[2].success && res[2].statusCode === 0 && res[2].data) {
          res[2].data.forEach((item: any) => {
            ChildList.push({
              id: item.id,
              memberType: 'Child',
              name: item.memberName
            })
          })
        }
        memberList = MainList.concat(NextList.concat(ChildList))
      }
    }
    if (mainMember && mainMember.id) {
      this.mainMember = mainMember
      this.fetchTransactions('LifeInsurance', mainMember.id, this.fetchMainInsurance)
      this.fetchSummaryMain(mainMember)
      this.fetchLife(mainMember)
    }
    memberList.forEach((item, idx) => {
      if (idx > 0) {
        if (item.memberType === 'Self') {
          item.title = '本人的重疾保障'
        } else if (item.memberType === 'Mate') {
          item.title = '配偶的重疾保障'
        } else {
          item.title = item.name + '的重疾保障'
        }
      } else {
        item.title = '经济支柱的重疾保障'
      }
      this.$set(this.insuranceListParam, item.id, {
        insuranceType: 'CriticalIllnessInsurance',
        orderField: 'ranking',
        doNotGroup: true
      })
      this.$set(this.enterListMny, item.id, '')
      this.$set(this.selectYearListValue, item.id, '')
      this.$set(this.insuranceListValue, item.id, '')
      this.fetchTransactions('CriticalIllnessInsurance', item.id, this.fetchInsurance)
      this.fetchSummary(item, idx)
      this.fetchCritical(item)
    })
    this.memberList = memberList
  }
  async preFetch() {
    const res = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      this.inAmount = res.data.customerMember.inAmount
    } else {
      this.$message.error(res.errorMsg)
    }
  }
  handleWindowOpen(url:any) {
    window.open(url)
  }
}
